import {
  acceptOnlyFilterdKeys,
  arrangeFiltersByOrder,
  closeFJDifferentModal,
  commonPropsForSSR,
  filterDropItems,
  getNumFormatter,
  getURLSearchParams,
  getfullUrl,
  handleMouseMoveAway,
  logApiResponseTime,
  makeApiCalls,
  makeFlexJobsWizardBoolean,
  makeTitle,
  modifyQueryLocation,
  modifySelectedLocation,
  readCookie,
} from '@components/common';
import {
  API_NAMES,
  WIDGETS_NAME,
  _CONSTANTS,
  allowedSectionsToHide,
  remoteJobsList,
} from '@components/common/constant';
import {
  getFilterQueryString,
  mapQueryParams,
} from '@components/common/filterHelper';
import showUSWorldCheckbox from '@components/common/usWorlCheckbox';
import FlexJobDifferModal from '@components/companies/unAuthPopup';
import JobListingContainer from '@components/jobListContainer';
import {
  CompaniesHiringCount,
  CountryResponseProps,
  ICompanies,
  IDeviceType,
  IJob,
  IJobCount,
  JobsCategories,
  SuccessStoriesType,
} from '@components/shared.types';
import SoftRegPopup from '@components/softRegPopup';

import { RemoteCategoriesMetaTags } from '@components/metaComponent/remoteCategories';
import { width } from '@styles/device.styled';
import useMediaQuery from '@utils/useMediaQuery';
import { getJobCategories, getRemoteJobsByCategory } from 'API/ApiHelpers';
import {
  getCompaniesHiringCount,
  getFirstJobDetails,
  getJobDetails,
  getJobDetailsApiUrl,
  getLocalizationAndConfig,
  getSavedJobsId,
} from 'API/jobs';
import { getOrUpdateGUID } from 'helper/eventTrackingUtils';
import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const BottomDrawer = dynamic(
  () =>
    import('@license-admin/boldfjcomponents').then(
      (module) => module.BottomDrawer
    ),
  { ssr: false }
);

// ! TODO: Change the data (any) to actual type
type ResponseProps = {
  isLoggedIn: boolean;
  showJobsCount: boolean | undefined;
  isPremiumUser: boolean;
  localization: any;
  saveJobIds: Array<string>;
  categorizedJobs: any;
  searchedJobTitle?: string;
  jobsData: JobsDataType;
  newsData: any;
  queryParams: { [key: string]: Array<string> | string | undefined };
  successStoriesList: Array<SuccessStoriesType>;
  headerProps: Array<string> | null;
  deviceType: IDeviceType;
  selectedFilters: { [key: string]: Array<string> };
  selectedLocation: string;
  selectedCountry: string;
  countyList?: CountryResponseProps[];
  headerSearch?: boolean;
  showWorkAnywhereInUsCheckbox: boolean;
  showWorkAnywhereInWorldCheckbox: boolean;
  breadCrumbName: string;
  isSearchPage: boolean;
  showAllDropFilters: boolean;
  companiesHiringCount: CompaniesHiringCount;
  isWorkAnywhereInUsChecked?: boolean;
  isWorkAnywhereInWorldChecked?: boolean;
  localizationWhyFlexJobs: any;
  localizationSoftRegModal: any;
  localizationSignUp: any;
  config: any;
  referer: string;
  url: string;
  hiringCount: IJobCount;
  isFromWizardPage: boolean;
  jobDetailsApiUrl: any;
  firstJobDetails: IJob;
  firstJobComDetails: ICompanies;
  hideJobCount?: boolean;
  searchPageExpVariant?: number;
};

type Company = {
  accolades: Array<AccoladesType>;
  logo: string;
  name: string;
  slug: string;
};

type JobsType = {
  id: string;
  title: string;
  saved: boolean;
  featured: boolean;
  allowedCandidateLocation: Array<string>;
  company: Company;
  description: string;
  jobLocations: Array<any>;
  jobSchedules: Array<string>;
  jobTypes: Array<string>;
  postedDate: string;
  remoteOptions: Array<any>;
};

type FiltersType = {
  name: string;
  categoryWithCount: CategoryWithCount;
  category?: JobsCategories[];
};

type FiltersDataByOrderType = {
  name: string;
  categoryWithCount: CategoryWithCount;
  slug: string;
};

type CategoryWithCount = {
  [key: string]: number;
};

type JobsDataType = {
  jobFilters: Array<FiltersType>;
  jobs: {
    currentPage: number;
    resultPerPage: number;
    totalCount: number;
    totalPages: number;
    results: Array<JobsType>;
  };
};

type AccoladesType = {
  imgSrc: string | undefined;
  title: string | undefined;
  description: string | null;
  accoladeGroups: Array<any>;
  companies: Array<any>;
  accolades: Array<any>;
};

export default function NewJobs(props: ResponseProps) {
  const {
    jobsData,
    queryParams,
    localization,
    successStoriesList,
    newsData,
    isPremiumUser,
    isLoggedIn,
    deviceType,
    isSearchPage,
    localizationWhyFlexJobs,
    localizationSoftRegModal,
    isFromWizardPage,
    categorizedJobs,
  } = props;
  const [showFlexJobsDifferent, setShowFlexJobsDifferent] = useState(false);
  const [isSoftRegModal, setIsSoftRegModal] = useState(false);
  let isSoftRegAlreadyShown = readCookie(_CONSTANTS.SOFT_REG_POPUP_DISPLAYED);
  const router = useRouter();
  const mobileView =
    useMediaQuery(width.tabletSm) || props.deviceType === 'mobile';
  let jobFilterDataByOrder: Array<FiltersDataByOrderType> =
    arrangeFiltersByOrder(
      jobsData?.jobFilters || [],
      true,
      queryParams,
      deviceType
    );
  let queryParamsByOrder: {
    [key: string]: Array<string> | string | undefined;
  } = arrangeFiltersByOrder(queryParams);

  const [guid, setGuid] = useState<any>(getOrUpdateGUID()); // for event tracking

  const WidgetsToShow = isPremiumUser
    ? [WIDGETS_NAME.NEWS, WIDGETS_NAME.CURRENTLY_HIRING]
    : [
        WIDGETS_NAME.PROMO_BANNER,
        WIDGETS_NAME.NEWS,
        WIDGETS_NAME.CURRENTLY_HIRING,
        WIDGETS_NAME.SUCCESS_STORIES,
        !isLoggedIn && WIDGETS_NAME.NEWS_LETTER,
        WIDGETS_NAME.MPR,
      ];

  const pathname = useRouter();

  const searchTitle: string = makeTitle(pathname.query);

  const breadcrumbDynamicValues: any = props.searchedJobTitle?.length
    ? 'for ' + props.searchedJobTitle
    : '';

  const handleMouseMove = (e: any) =>
    handleMouseMoveAway(e, setIsSoftRegModal, !isSoftRegAlreadyShown);

  let softRegPopupTimout: any = null;
  const numFormat = getNumFormatter();
  const getSoftRegPopUpHeading = () => {
    if (!isFromWizardPage) {
      if (jobsData.jobs.totalCount < 200) {
        return localizationSoftRegModal.m_soft_reg_heading.replace(
          '{0}',
          'remote'
        );
      }
    }
  };

  const getSoftRegPopUpSubHeading = () => {
    if (isFromWizardPage) {
      if (jobsData?.jobs?.totalCount && jobsData.jobs.totalCount >= 200) {
        return localizationSoftRegModal.dont_miss_oppurtunity_heading.replace(
          `<span class='highlighted-text'>awesome</span>`,
          deviceType === 'mobile'
            ? `<span class='highlighted-text'>${numFormat.format(
                jobsData.jobs.totalCount
              )}</span>` + '<br/>'
            : `<span class='highlighted-text'>${numFormat.format(
                jobsData.jobs.totalCount
              )}</span>`
        );
      } else {
        return localizationSoftRegModal.dont_miss_oppurtunity_heading.replace(
          'job matches',
          deviceType === 'mobile' ? '<br/> job matches' : 'job matches'
        );
      }
    } else {
      if (deviceType === 'mobile') {
        const jobsCount = numFormat.format(
          jobsData.jobs.totalCount >= 200
            ? jobsData.jobs.totalCount
            : props.hiringCount.jobsCount
        );

        return localizationSoftRegModal.m_soft_reg_subHeading.replace(
          '{0}',
          jobsCount
        );
      } else {
        return props.localizationSignUp.dont_miss_oppurtunity_heading;
      }
    }
  };

  const getSoftRegPopUpDescription = () => {
    if (isFromWizardPage) {
      return localizationSoftRegModal.get_started_desc.replace(
        '{count}',
        numFormat.format(props.hiringCount.jobsCount)
      );
    } else {
      return props.localizationSignUp.get_started_desc_enter_email;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      document.addEventListener('mouseleave', handleMouseMove);
    }, 15000);

    return () => {
      document.removeEventListener('mouseleave', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    if (isFromWizardPage) {
      clearTimeout(softRegPopupTimout);
      softRegPopupTimout = setTimeout(() => {
        setIsSoftRegModal(true);
      }, 4000);
    }

    setShowFlexJobsDifferent(
      makeFlexJobsWizardBoolean(props.referer, isPremiumUser, isSearchPage)
    );
  }, []);

  useEffect(() => {
    // show mobile pop up after given time //

    const time = setTimeout(async () => {
      if (mobileView && !isSoftRegAlreadyShown) {
        setIsSoftRegModal(true);
      }
    }, 15000);

    return () => clearTimeout(time);
  }, [isSoftRegAlreadyShown, mobileView]);

  useEffect(() => {
    if (props.jobDetailsApiUrl) {
      console.log('jobDetailsApiUrl-->', props.jobDetailsApiUrl);
    }
  }, []);
  return (
    <>
      <Head>{RemoteCategoriesMetaTags({ categoryName: 'index' })}</Head>
      {!isFromWizardPage && showFlexJobsDifferent && (
        <FlexJobDifferModal
          closeModal={() => closeFJDifferentModal(setShowFlexJobsDifferent)}
          localizeflexjobPopup={localizationWhyFlexJobs}
        />
      )}
      {!isLoggedIn && isSoftRegModal && !isSoftRegAlreadyShown && (
        <SoftRegPopup
          companiesHiringCount={props.companiesHiringCount}
          mobileView={mobileView}
          localization={props.localizationSignUp}
          closeModal={() => {
            if (!isFromWizardPage) setIsSoftRegModal(false);
          }}
          hiringCount={props.hiringCount}
          config={props.config}
          setPopupState={setIsSoftRegModal}
          isSoftRegModal={isSoftRegModal}
          returnUrl={
            isFromWizardPage
              ? `${process.env.NEXT_PUBLIC_ROOT_URL}/payment/index`
              : ''
          }
          heading={getSoftRegPopUpSubHeading()}
          subheading={getSoftRegPopUpDescription()}
          isCloseAllowed={mobileView || !isFromWizardPage}
          currentUrl={''}
          isFromWizardPage={props.isFromWizardPage}
        />
      )}
      <JobListingContainer
        selectedCountry={props.selectedCountry}
        jobsData={jobsData}
        localization={localization}
        newsData={newsData}
        categorizedJobs={categorizedJobs}
        jobFilters={jobFilterDataByOrder}
        location={props.selectedLocation}
        queryParamsByOrder={queryParamsByOrder}
        successStoriesList={successStoriesList}
        savedJobsIdsList={props.saveJobIds}
        sectionsToHide={[
          allowedSectionsToHide.CATEGORY_TABS,
          allowedSectionsToHide.WIDGETS,
          allowedSectionsToHide.JOB_CARDS,
        ]}
        widgetsName={WidgetsToShow}
        searchedJobTitle={props.searchedJobTitle}
        isPremiumUser={isPremiumUser}
        isLoggedIn={isLoggedIn}
        selectedFilters={props.selectedFilters}
        showJobsCount={props.showJobsCount}
        breadCrumbName={props.breadCrumbName}
        breadCrumbNameValue={[breadcrumbDynamicValues]}
        deviceType={deviceType}
        showWorkAnywhereInUsCheckbox={props.showWorkAnywhereInUsCheckbox}
        showWorkAnywhereInWorldCheckbox={props.showWorkAnywhereInWorldCheckbox}
        showAllDropFilters={props.showAllDropFilters}
        isSearchPage={isSearchPage}
        setGuid={setGuid}
        guid={guid}
        isWorkAnywhereInUsChecked={props.isWorkAnywhereInUsChecked}
        isWorkAnywhereInWorldChecked={props.isWorkAnywhereInWorldChecked}
        countyList={props.countyList}
        firstJobDetails={props.firstJobDetails}
        firstJobComDetails={props.firstJobComDetails}
        hideJobCount={props.hideJobCount}
        searchPageExpVariant={props.searchPageExpVariant}
        hideJobCard={true}
        topBanner={{
          showBanner: true,
          bannerTitle: 'Remote Jobs',
        }}
        isRemoteJobsPage={true}
      />
    </>
  );
}

export const getServerSideProps: GetServerSideProps<ResponseProps> = async ({
  query,
  req,
  resolvedUrl = '',
}: GetServerSidePropsContext) => {
  let filterValues = acceptOnlyFilterdKeys(query);
  // if search page (searchPage=1) is set under web.config then it will be search page
  const isSearchPage = resolvedUrl.includes('searchPage');
  // const isSearchPage = true;
  const commonSSRProps = await commonPropsForSSR(
    req,
    isSearchPage ? 'simple-search' : 'jobs_page'
  );

  const { deviceType, cookies, isPremiumUser, isLoggedIn, cookieHeader } =
    commonSSRProps;
  const fullUrl = getfullUrl(req, isSearchPage ? '/search' : undefined);
  let countryName = '';
  // Show check boxes in mobile. Checked by default for mobile
  // preference order of work from anywhere check boxes
  // 1. Status present as query param (here using userClickedUSOrWorldCheckbox)
  // 2. Status present in saved search result
  // 3. Default status
  const userClickedUSOrWorldCheckbox =
    query.anywhereinus || query.anywhereinworld;

  let shouldShowUSWorldCheckbox = deviceType === 'mobile' ? true : false;
  let {
    queryVals,
    isWorkAnywhereInWorldChecked,
    isWorkAnywhereInUsChecked,
    showWorkAnywhereInUsCheckbox,
    showWorkAnywhereInWorldCheckbox,
  } = showUSWorldCheckbox(query, shouldShowUSWorldCheckbox);
  query = queryVals;
  let selectedLocation: string = getURLSearchParams(query, 'joblocations');

  const apiCallList = [
    API_NAMES.COUNTRY_LIST_EB,
    API_NAMES.NEWS_WIDGET,
    API_NAMES.SUCCESS_STORY_WIDGET,
    ...(isPremiumUser ? [] : [API_NAMES.HIRING_COUNT]),
  ];

  const [
    countyListServiceRes,
    newsResponse = {},
    successStoriesList = {},
    companiesHiringCount,
  ] = await makeApiCalls(apiCallList);

  /**
   *  Below code is to modify the lookup service response remove @@@ and null values
   */

  let { locationObj, isInternationalLocation, locationNameAndState }: any =
    modifySelectedLocation(selectedLocation, countyListServiceRes);
  if (selectedLocation) {
    selectedLocation = locationNameAndState;
    countryName = locationObj.countryName;
    query = modifyQueryLocation(locationObj, isInternationalLocation, query);
  }

  let categorizedJobs = await getRemoteJobsByCategory(
    remoteJobsList,
    3,
    cookieHeader
  );
  query = mapQueryParams(query);
  let queryString: string = getFilterQueryString(query);

  /**
   *  Below code is to get the savedsearch id and page
   *  If savedsearch id is present the api call will change to savedsearch api
   *  Using req.url because query is replacing + with space
   */

  let selectedFilters: any = filterValues || {};

  if (deviceType === 'mobile') {
    showWorkAnywhereInUsCheckbox = isInternationalLocation ? false : true;
    showWorkAnywhereInWorldCheckbox = true;
  }
  /**
   *  Below code is to get the search keyword and location from the query string
   *  If searchKeyWord and selectedLocation is used to show pre-selected filters
   */

  let sortByPostedDate = false;
  let searchKeyword: string = getURLSearchParams(queryString, 'searchkeyword');

  const previousUrl = req.headers.referer || '/';
  const isFromWizardPage =
    previousUrl.includes('job_wizard') || previousUrl.includes('wizard');

  let breadCrumbName = isPremiumUser
    ? 'rmc-remote-jobs-auth'
    : 'rmc-remote-jobs-unauth';

  if (isSearchPage) {
    breadCrumbName = isPremiumUser
      ? 'simple-search-premium'
      : 'simple-search-public';
  }

  if (!searchKeyword) {
    sortByPostedDate = true;
  }
  const searchPromise = getJobDetails(
    `search${queryString.slice(0, -1)}`,
    cookieHeader,
    sortByPostedDate,
    isSearchPage ? false : true,
    req.headers
  );
  const searchWrapperPromise = logApiResponseTime(searchPromise, 'search');

  let jobDetailsApiUrl = '';
  if (query.debug) {
    jobDetailsApiUrl = getJobDetailsApiUrl(
      `search${queryString.slice(0, -1)}`,
      cookieHeader,
      sortByPostedDate,
      isSearchPage ? false : true,
      req.headers
    );
  }

  const promiseList = [
    searchWrapperPromise,
    getSavedJobsId(isPremiumUser, cookieHeader),
    getJobCategories(cookieHeader),
    getLocalizationAndConfig('*'),
    getCompaniesHiringCount(),
  ];

  const [
    { data },
    saveJobIds,
    jobsCategories,
    [localizationGenereic],
    hiringCount,
  ] = await Promise.all<any>(promiseList);

  // Sort the filters drop items
  if (data?.jobFilters && Array.isArray(data.jobFilters)) {
    data.jobFilters = filterDropItems({
      filterData: data.jobFilters,
      categoriesData: jobsCategories.data,
      query: queryString,
    });
  }
  // If search API contains category overide the category list else add

  const firstJobData = await getFirstJobDetails(
    data?.jobs?.results?.[0],
    cookieHeader,
    isPremiumUser
  );

  let searhPageExperimentVariant: number = 1;

  return {
    props: {
      ...commonSSRProps,
      companiesHiringCount: companiesHiringCount || {},
      categorizedJobs: categorizedJobs.data || [],
      jobsData: data,
      newsData: newsResponse.data || [],
      queryParams: query,
      selectedFilters: selectedFilters,
      saveJobIds: saveJobIds || [],
      successStoriesList: successStoriesList.data || [],
      headerProps: isSearchPage
        ? null
        : [
            '/remote-jobs',
            '/remote-jobs/legitimate-work-from-home-jobs-hiring-now',
          ],
      showJobsCount: true,
      searchedJobTitle: searchKeyword || '',
      selectedLocation: selectedLocation,
      selectedCountry: countryName || '',
      breadCrumbName: breadCrumbName,
      deviceType: deviceType,
      showWorkAnywhereInUsCheckbox: showWorkAnywhereInUsCheckbox,
      showWorkAnywhereInWorldCheckbox: showWorkAnywhereInWorldCheckbox,
      isSearchPage: isSearchPage,
      showAllDropFilters:
        cookies[_CONSTANTS.SET_SHOW_ALL_DROP_FILTERS] === 'true' ? true : false,
      isWorkAnywhereInWorldChecked,
      isWorkAnywhereInUsChecked,
      localizationWhyFlexJobs: localizationGenereic['how_flexjob_different'],
      localizationSignUp: localizationGenereic['signup'],
      localizationSoftRegModal: localizationGenereic['simple-search'],
      referer: req?.headers?.referer || '',
      url: fullUrl,
      hiringCount,
      isFromWizardPage,
      countyList: countyListServiceRes,
      jobDetailsApiUrl: jobDetailsApiUrl,
      searchPageExpVariant: searhPageExperimentVariant || 1,
      ...firstJobData,
      fullwidth: true,
      hideJobCount:
        isSearchPage &&
        selectedLocation === '' &&
        Object.keys(selectedFilters).length === 0 &&
        searchKeyword === '',
    },
  };
};
