import { getAbsoluteImgPath, sessionStorageSetItem } from '@components/common';
import { _CONSTANTS } from '@components/common/constant';
import { genericClickEvent } from '@components/common/event-tracker';
import { IDeviceType } from '@components/shared.types';
import { Button, Popup } from '@license-admin/boldfjcomponents';
import { width } from '@styles/device.styled';
import useMediaQuery from '@utils/useMediaQuery';
import { isPortalContent } from 'helper';
import { POP_UP_EVENT } from 'helper/eventTrackingUtils/constant';
import { useEffect } from 'react';
import {
  BtnWrapper,
  ContentAuthor,
  DetailContent,
  DifferentText,
  ModalContent,
  ModalHeaderImage,
  ModalInnerWrap,
  PointerList,
  PointerListItem,
  PointersWrap,
  QuesHead,
  QuotedText,
  RemoteListHead,
} from './styled';

type IProps = {
  closeModal: () => void;
  localizeflexjobPopup: any;
  deviceType?: IDeviceType;
  companyRedesignVariant?: string;
  isCompanyPageExp?: boolean;
};

const FlexJobDifferModal = (props: IProps) => {
  const modalCardData = [
    {
      imgsrc: getAbsoluteImgPath('/images/sem-landing/icn-gems.svg'),
      content: props.localizeflexjobPopup.card_details_1,
      height: 60,
      width: 60,
      isMobile: true,
    },
    {
      imgsrc: getAbsoluteImgPath('/images/sem-landing/icn-filters.svg'),
      content: props.localizeflexjobPopup.card_details_2,
      height: 60,
      width: 60,
    },
    {
      imgsrc: getAbsoluteImgPath('/images/sem-landing/icn-time.svg'),
      content: props.localizeflexjobPopup.card_details_3,
      height: 60,
      width: 60,
      isMobile: true,
    },
  ];

  const isMobileView =
    useMediaQuery(width.tablet) || props.deviceType === 'mobile';

  useEffect(() => {
    sessionStorageSetItem(_CONSTANTS.FLEXJOBS_DIFFERENT_POP_UP_KEY, 'TRUE');
    genericClickEvent(
      POP_UP_EVENT.how_FJ_is_diff_viewed.event,
      POP_UP_EVENT.how_FJ_is_diff_viewed.properties
    );
  }, []);

  const clickHandler = () => {
    window.location.href = '/signup';
    props.closeModal();
  };
  return (
    <>
      <Popup
        modalSize={props.isCompanyPageExp ? 'xlgPlus' : 'xlg'}
        closeModal={props.closeModal}
        isUnAuthPopup
        isContentOverflow
        popupClass={props.isCompanyPageExp ? 'how-different-popup' : ''}
        hideCloseModal={props.isCompanyPageExp}
      >
        <ModalInnerWrap isCompanyPageExp={props.isCompanyPageExp}>
          <ModalContent>
            <ModalHeaderImage isCompanyPageExp={props.isCompanyPageExp}>
              <img
                src={`${getAbsoluteImgPath(
                  '/images/fj-logo-w-white-text.svg'
                )}`}
                alt={`${isPortalContent}: Search The Best Remote Work From Home and Flexible Jobs`}
                role="link"
                title={`${isPortalContent}: Search The Best Remote Work From Home and Flexible Jobs`}
                width={160}
                height={69}
              />
            </ModalHeaderImage>
            {!isMobileView ? (
              <PointersWrap>
                <QuesHead>
                  {props.localizeflexjobPopup.how_job_head}
                  <DifferentText
                    iconPath={`${getAbsoluteImgPath(
                      '/images/tour/img-orange-underline.svg'
                    )}`}
                  >
                    {props.localizeflexjobPopup.how_different}
                  </DifferentText>
                </QuesHead>
                <PointerList isCompanyPageExp={props.isCompanyPageExp}>
                  {modalCardData.map((data, index) => {
                    return (
                      <div key={index}>
                        <img
                          height={data.height}
                          width={data.width}
                          src={data.imgsrc}
                          alt="icons"
                        />
                      </div>
                    );
                  })}
                </PointerList>
                <PointerList isCompanyPageExp={props.isCompanyPageExp}>
                  {modalCardData.map((data, index) => {
                    return (
                      <PointerListItem key={index}>
                        <RemoteListHead>
                          {data.content.details_head}
                        </RemoteListHead>
                      </PointerListItem>
                    );
                  })}
                </PointerList>
                <PointerList
                  isStartData
                  isCompanyPageExp={props.isCompanyPageExp}
                >
                  {modalCardData.map((data, index) => {
                    return (
                      <PointerListItem key={index}>
                        <DetailContent>
                          {data.content.details_content}
                        </DetailContent>
                      </PointerListItem>
                    );
                  })}
                </PointerList>
                <PointerList
                  isStartData
                  isCompanyPageExp={props.isCompanyPageExp}
                >
                  {modalCardData.map((data, index) => {
                    return (
                      <PointerListItem key={index}>
                        <QuotedText
                          isCompanyPageExp={props.isCompanyPageExp}
                          imsrcone={getAbsoluteImgPath(
                            '/images/sem-landing/icn-quotes-left.svg'
                          )}
                          imsrctwo={getAbsoluteImgPath(
                            '/images/sem-landing/icn-quotes-right.svg'
                          )}
                          dangerouslySetInnerHTML={{
                            __html: data.content.details_feed,
                          }}
                        ></QuotedText>
                        <ContentAuthor>
                          {data.content.details_auth}
                        </ContentAuthor>
                      </PointerListItem>
                    );
                  })}
                </PointerList>
                <BtnWrapper
                  isCompanyPageExp={props.isCompanyPageExp}
                  onClick={() =>
                    genericClickEvent(
                      POP_UP_EVENT.how_FJ_is_diff_clicked.event,
                      POP_UP_EVENT.how_FJ_is_diff_clicked.properties
                    )
                  }
                >
                  <Button
                    text="Let's Go!"
                    buttonType="tertiary"
                    buttonSize="lg"
                    fullLength
                    clickHandler={() => clickHandler()}
                    underlineOnHover
                  />
                </BtnWrapper>
              </PointersWrap>
            ) : (
              <PointersWrap isCompanyPageExp={props.isCompanyPageExp}>
                <QuesHead isCompanyPageExp={props.isCompanyPageExp}>
                  {props.localizeflexjobPopup.how_job_head}
                  <DifferentText
                    iconPath={`${getAbsoluteImgPath(
                      '/images/tour/img-orange-underline.svg'
                    )}`}
                  >
                    {props.localizeflexjobPopup.how_different}
                  </DifferentText>
                </QuesHead>
                {modalCardData.map((data, index) => {
                  return (
                    data.isMobile && (
                      <>
                        <div key={index}>
                          <img
                            height={data.height}
                            width={data.width}
                            src={data.imgsrc}
                            alt="icons"
                          />
                        </div>
                        <PointerListItem key={index}>
                          <RemoteListHead
                            isCompanyPageExp={props.isCompanyPageExp}
                          >
                            {data.content.details_head}
                          </RemoteListHead>
                        </PointerListItem>
                        <PointerListItem key={index}>
                          <DetailContent>
                            {data.content.details_content_wizard}
                          </DetailContent>
                        </PointerListItem>
                        <PointerListItem key={index}>
                          <QuotedText
                            isCompanyPageExp={props.isCompanyPageExp}
                            imsrcone={getAbsoluteImgPath(
                              '/images/sem-landing/icn-quotes-left.svg'
                            )}
                            imsrctwo={getAbsoluteImgPath(
                              '/images/sem-landing/icn-quotes-right.svg'
                            )}
                            dangerouslySetInnerHTML={{
                              __html: data.content.details_feed,
                            }}
                          ></QuotedText>
                          <ContentAuthor
                            isCompanyPageExp={props.isCompanyPageExp}
                          >
                            {data.content.details_auth}
                          </ContentAuthor>
                        </PointerListItem>
                      </>
                    )
                  );
                })}
                <BtnWrapper
                  onClick={() =>
                    genericClickEvent(
                      POP_UP_EVENT.how_FJ_is_diff_clicked.event,
                      POP_UP_EVENT.how_FJ_is_diff_clicked.properties
                    )
                  }
                >
                  <Button
                    text="Let's Go!"
                    buttonType="tertiary"
                    buttonSize="lg"
                    fullLength
                    clickHandler={props.closeModal}
                    underlineOnHover
                  />
                </BtnWrapper>
              </PointersWrap>
            )}
          </ModalContent>
        </ModalInnerWrap>
      </Popup>
    </>
  );
};

export default FlexJobDifferModal;
