import { device } from '@styles/device.styled';
import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';

export const PopupWrapper = styled.div<{ isCompanyPageExp?: boolean }>`
  margin: 0;
  ${({ isCompanyPageExp }) =>
    isCompanyPageExp &&
    css`
      .how-different-popup {
        width: 93vw;
      }
    `}
`;
export const ModalInnerWrap = styled.div<{ isCompanyPageExp?: boolean }>`
  padding: ${({ isCompanyPageExp }) =>
    isCompanyPageExp ? '0rem 4rem 4rem' : '  0 1.6rem 1.6rem;'};
  ${({ theme: { UnAuthPopupStyle } }) =>
    UnAuthPopupStyle &&
    css`
      background-color: ${UnAuthPopupStyle.BgColors.modalinnerwrap};
    `}
  ${device.mobileLg} {
    ${({ isCompanyPageExp }) =>
      isCompanyPageExp &&
      css`
        padding: 1.5rem 1.2rem 2.1rem;
      `}
  }
`;
export const ModalHead = styled.div`
  ${Mixin.HorizontalCenter({ justify: 'flex-end' })}
`;

export const ModalHeaderImage = styled.div<{ isCompanyPageExp?: boolean }>`
  padding: ${({ isCompanyPageExp }) =>
    isCompanyPageExp ? '1.9rem 0 1.6rem' : ' 0 0 0.8rem'};
  ${({ isCompanyPageExp }) =>
    isCompanyPageExp &&
    css`
      img {
        width: 13.7rem;
      }
    `}
  ${device.mobileLg} {
    ${({ isCompanyPageExp }) =>
      isCompanyPageExp &&
      css`
        display: none;
      `}
  }
`;

export const ModalContent = styled.div`
  ${Mixin.AllCenter({ direction: 'column' })}
  ${({ theme: { UnAuthPopupStyle } }) =>
    UnAuthPopupStyle &&
    css`
      background-color: ${UnAuthPopupStyle.BgColors.modalcontentbg};
    `}
`;
export const QuesHead = styled.h2<{ isCompanyPageExp?: boolean }>`
  font-weight: 600;
  margin-bottom: 2.4rem;
  ${device.mobileLg} {
    ${({ isCompanyPageExp }) =>
      isCompanyPageExp &&
      css`
        margin-bottom: 1.8rem;
      `}
  }
`;

export const DifferentText = styled.span<{ iconPath?: string }>`
  position: relative;
  ${({ iconPath }) =>
    iconPath &&
    css`
      background: bottom center url(${iconPath}) no-repeat;
      background-size: contain;
      padding-bottom: 0.9rem;
    `}
`;

export const PointersWrap = styled.div<{ isCompanyPageExp?: boolean }>`
  padding: 2.4rem 4.8rem;
  border-radius: 0.4rem;
  ${Mixin.AllCenter({ direction: 'column' })}
  ${({ theme: { UnAuthPopupStyle } }) =>
    UnAuthPopupStyle &&
    css`
      background-color: ${UnAuthPopupStyle.BgColors.pointerswrapbg};
    `}

    ${device.mobileLg} {
    ${({ isCompanyPageExp }) =>
      isCompanyPageExp &&
      css`
        padding: 2.1rem 2.8rem 2.7rem;
        gap: 0.2rem;
      `}
  }
`;

export const PointerList = styled.div<{
  isStartData?: boolean;
  isCompanyPageExp?: boolean;
}>`
  ${({ isStartData }) =>
    isStartData
      ? css`
          ${Mixin.GridLayout({
            gridCount: 3,
            columnGap: '2.4rem',
            alignItems: 'self-start',
          })}
        `
      : css`
          ${Mixin.GridLayout({
            gridCount: 3,
            columnGap: '2.4rem',
            alignItems: 'center',
          })}
        `}

  ${({ isCompanyPageExp }) =>
    isCompanyPageExp &&
    css`
      column-gap: 6.4rem;
    `}
  width:100%;
  margin-bottom: 0.8rem;
  text-align: center;
`;

export const PointerListItem = styled.div`
  ${Mixin.AllCenter({ direction: 'column' })}
  text-align: center;
`;

export const DetailContent = styled.p`
  ${device.mobileTab} {
    margin: 0;
  }
  ${({ theme: { UnAuthPopupStyle } }) =>
    UnAuthPopupStyle &&
    css`
      font-size: ${UnAuthPopupStyle.FontSizes.detailContent};
    `}
`;

export const RemoteListHead = styled.h4<{ isCompanyPageExp?: boolean }>`
  padding-top: 1.6rem;
  font-weight: 600;
  ${({ theme: { UnAuthPopupStyle } }) =>
    UnAuthPopupStyle &&
    css`
      color: ${UnAuthPopupStyle.Colors.listhead};
    `}
  ${device.mobileLg} {
    ${({ isCompanyPageExp }) =>
      isCompanyPageExp &&
      css`
        padding-top: 0.8rem;
        font-size: 1.6rem;
      `}
  }
`;

export const QuotedText = styled.blockquote<{
  imsrcone?: string;
  imsrctwo?: string;
  isCompanyPageExp?: boolean;
}>`
  position: relative;
  font-style: italic;
  margin: 0;
  padding: 0.8rem 0;
  ${({ isCompanyPageExp }) =>
    isCompanyPageExp &&
    css`
      font-weight: 500;
    `}
  ${({ theme: { UnAuthPopupStyle } }) =>
    UnAuthPopupStyle &&
    css`
      color: ${UnAuthPopupStyle.Colors.quotedtextColor};
      font-size: ${UnAuthPopupStyle.FontSizes.quotedtextfs};
    `}
  &:before {
    content: '';
    background: url(${({ imsrcone }) => imsrcone && imsrcone}) no-repeat;
    background-size: 1.3rem 1.3rem;
    display: inline-flex;
    height: 1.3rem;
    width: 1.3rem;
    margin-right: 0.8rem;
  }
  &:after {
    content: '';
    background: url(${({ imsrctwo }) => imsrctwo && imsrctwo}) no-repeat;
    background-size: 1.3rem 1.3rem;
    display: inline-flex;
    height: 1.3rem;
    width: 1.3rem;
    margin-left: 0.8rem;
  }

  ${device.mobileLg} {
    ${({ isCompanyPageExp }) =>
      isCompanyPageExp &&
      css`
        padding: 0.6rem 1.9rem;
      `}
  }
`;

export const ContentAuthor = styled.p<{ isCompanyPageExp?: boolean }>`
  ${({ theme: { UnAuthPopupStyle } }) =>
    UnAuthPopupStyle &&
    css`
      font-size: ${UnAuthPopupStyle.FontSizes.contentAuthor};
    `}
  ${device.mobileLg} {
    ${({ isCompanyPageExp }) =>
      isCompanyPageExp &&
      css`
        margin-bottom: 1.5rem;
      `}
  }
`;

export const BtnWrapper = styled.div<{
  isNextRemote?: boolean;
  isCompanyPageExp?: boolean;
}>`
  max-width: ${({ isNextRemote }) => (isNextRemote ? '43.5rem' : '27rem')};
  width: 100%;
  margin-bottom: 1.2rem;
  ${({ isCompanyPageExp }) =>
    isCompanyPageExp &&
    css`
      button {
        padding: 0.35rem 1.6rem;
      }
    `}
`;
